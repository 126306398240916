body {
    background:
    linear-gradient(
      rgba(0, 0, 0, 0.3), 
      rgba(0, 0, 0, 0.3)
    ),
    url(https://i.pinimg.com/originals/8f/14/57/8f14578ef69a4c53352955d1e072eda6.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

* {
    margin: 0;
    padding: 0;
}
.app{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
}
.videoplayer{
  display: flex;
  gap: 1em;
  justify-content: center;
  align-items: center;
 
}
.paper1{
  display: flex;
  padding: 10px 20px;
  width: 60%;
}
video{
  width: 100%;
}
@media screen and (max-width:796px){
  .videoplayer{
    flex-direction: column;
    flex-wrap: wrap;
  }
  
}
.lms{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  padding: 20px 10px;
  flex-direction: column
}
.form{
  display: flex;
  gap: 2em;
  flex-direction: column;
}
@media screen and (max-width:796px){
  .form{
    flex-wrap: wrap;
    justify-content: center;
    /* align-items: center; */
  }
}
